<template>
  <div>
    <input type="hidden" :value="value" />
  </div>
</template>

<script>
import { base } from "@/components/Tools/FormHelper/Helper/mixins";

export default {
  components: {},
  mixins: [base],
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>
